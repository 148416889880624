/* eslint-disable */
export const items = [
    {
        id         : 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
        folderId   : null,
        name       : 'Osobné',
        createdBy  : 'Martin Jakubec',
        createdAt  : '24 april, 2021',
        modifiedAt : '25 april, 2021',
        size       : '7 MB',
        type       : 'folder',
        contents   : '2 súbory',
        description: 'osobné navrhy dokumentov potrebné k práci'
    },
    {
        id         : 'ed58add1-45a7-41db-887d-3ca7ee7f2719',
        folderId   : null,
        name       : 'Systémové',
        createdBy  : 'Martin Jakubec',
        createdAt  : '8 máj, 2021',
        modifiedAt : '8 máj, 2021',
        size       : '2 MB',
        type       : 'folder',
        contents   : '11 súborov',
        description: 'Zdieľané súbory pracovnej skupiny'
    },
    {
        id         : '5cb66e32-d1ac-4b9a-8c34-5991ce25add2',
        folderId   : 'cd6897cb-acfd-4016-8b53-3f66a5b5fc68',
        name       : 'Vypoved zmluvy AXA',
        createdBy  : 'Martin Jakubec',
        createdAt  : '14 január, 2021',
        modifiedAt : '14 január, 2021',
        size       : '1.2 MB',
        type       : 'PDF',
        contents   : null,
        description: null
    },
    {
        id         : '3ffc3d84-8f2d-4929-903a-ef6fc21657a7',
        folderId   : null,
        name       : 'Finančný rozpočet na rok 2022',
        createdBy  : 'Martin Jakubec',
        createdAt  : '15 november, 2021',
        modifiedAt : '15 november, 2021',
        size       : '679 KB',
        type       : 'XLS',
        contents   : null,
        description: null
    },
    {
        id         : '157adb9a-14f8-4559-ac93-8be893c9f80a',
        folderId   : null,
        name       : 'Nepovolené prečerpanie',
        createdBy  : 'Martin Jakubec',
        createdAt  : '8 máj, 2021',
        modifiedAt : '8 máj, 2021',
        size       : '1.5 MB',
        type       : 'DOC',
        contents   : null,
        description: null
    },
    {
        id         : '4f64597a-df7e-461c-ad60-f33e5f7e0747',
        folderId   : null,
        name       : 'FA 1202021456',
        createdBy  : 'Martin Jakubec',
        createdAt  : '12 január, 2021',
        modifiedAt : '12 január, 2021',
        size       : '1.8 MB',
        type       : 'PDF',
        contents   : null,
        description: null
    },
    {
        id         : 'e445c445-57b2-4476-8c62-b068e3774b8e',
        folderId   : null,
        name       : 'Návrh zmluvy DDS',
        createdBy  : 'Martin Jakubec',
        createdAt  : '8 jún, 2020',
        modifiedAt : '8 jún, 2020',
        size       : '11.3 MB',
        type       : 'DOC',
        contents   : null,
        description: null
    },
];
