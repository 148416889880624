/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboards.project',
        title: 'Finančná analýza',
        type : 'basic',
        icon : 'heroicons_outline:clipboard-check',
        link : '/financial-analysis',
        badge: {
            title  : '37%',
            classes: 'px-2 bg-yellow-300 text-black rounded-full'
        }
    },
    {
        id      : 'calculators',
        title   : 'Kalkulačky',
        type    : 'collapsable',
        icon    : 'heroicons_outline:calculator',
        children: [
            {
                id   : 'calculators.li.rzp',
                title: 'Rizikové poistenie',
                type : 'basic',
                link : '/calc/risk-insurance',
                badge: {
                    title  : 'NOVÉ',
                    classes: 'px-2.5 bg-teal-400 text-black rounded-full'
                }
            }
        ],
        /*
        children: [
            {
                id      : 'calculators.li',
                title   : 'Životné  a rizikové pois...',
                type    : 'collapsable',
                icon : 'heroicons_outline:heart',
                children: [
                    {
                        id   : 'calculators.li.rzp',
                        title: 'Audit IŽP',
                        type : 'basic',
                        link : '/calc/risk-insurance'
                    },
                    {
                        id   : 'calculators.li.rzp',
                        title: 'Rizikové poistenie',
                        type : 'basic',
                        link : '/calc/risk-insurance'
                    }
                ],

            },
            {
                id   : 'example',
                title: 'Sporenie na dôchodok',
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/example'
            },
        ],
         */

    },
    {
        id   : 'dashboards.project',
        title: 'Finančný plán',
        type : 'collapsable',
        icon : 'heroicons_outline:adjustments',
        children: [
            {
                id   : 'calculators.li',
                title: 'Aktuálna životná situácia',
                type : 'basic',
                link : '/financial-plan/overview'
            },
            {
                id   : 'calculators.li',
                title: 'Analýza banky',
                type : 'basic',
                link : '/financial-plan/insight'
            },
            {
                id   : 'example',
                title: 'Rozpočet klienta',
                type : 'basic',
                link : '/financial-plan/budget'
            },
            {
                id   : 'example',
                title: 'Platobný kalendár',
                type : 'basic',
                link : '/financial-plan/summary'
            },
            {
                id   : 'example',
                title: 'Komplexný finančný plán',
                type : 'basic',
                link : '/example'
            },
        ],
    },
    {
        id   : 'apps.tasks',
        title: 'Úlohy',
        type : 'basic',
        icon : 'heroicons_outline:check-circle',
        link : '/apps/tasks',
        badge : {}
    },
    {
        id  : 'divider-1',
        type: 'divider'
    },
    {
        id   : 'apps.dashboard',
        title: 'Úvod',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id   : 'apps.important',
        title: 'Dôležité čísla',
        type : 'basic',
        icon : 'heroicons_outline:exclamation',
        link : '/important-numbers'
    },
    {
        id   : 'apps.contacts',
        title: 'Klienti',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/apps/contacts'
    },
    {
        id   : 'apps.scrumboard',
        title: 'Procesy',
        type : 'basic',
        icon : 'heroicons_outline:view-boards',
        link : '/apps/scrumboard'
    },
    {
        id   : 'dashboards.project',
        title: 'Servis',
        type : 'basic',
        icon : 'heroicons_outline:annotation',
        link : '/service'
    },
    {
        id   : 'apps.network',
        title: 'Štruktúra',
        type : 'basic',
        icon : 'heroicons_outline:share',
        link : '/network'
    },
    {
        id   : 'apps.notes',
        title: 'Poznámky',
        type : 'basic',
        icon : 'heroicons_outline:pencil-alt',
        link : '/apps/notes'
    },
    {
        id   : 'apps.file-manager',
        title: 'Dokumenty',
        type : 'basic',
        icon : 'heroicons_outline:document-download',
        link : '/apps/file-manager'
    },
    {
        id  : 'divider-2',
        type: 'divider'
    },
    // {
    //     id      : 'pages.pricing',
    //     title   : 'Cena',
    //     type    : 'basic',
    //     icon    : 'heroicons_outline:cash',
    //     link : '/pricing'
    // },
    {
        id   : 'apps.academy',
        title: 'Akadémia',
        type : 'basic',
        icon : 'heroicons_outline:academic-cap',
        link : '/apps/academy'
    },
    {
        id      : 'apps.help-center',
        title   : 'Pomoc a podpora',
        type    : 'basic',
        icon    : 'heroicons_outline:support',
        link    : '/apps/help-center',
    },
    // {
    //     id      : 'apps',
    //     title   : 'Vzdelávanie',
    //     subtitle: 'Pomôžeme vám v osobnom rozvoji',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:home',
    //     children: [
    //         {
    //             id   : 'apps.academy',
    //             title: 'Akadémia',
    //             type : 'basic',
    //             icon : 'heroicons_outline:academic-cap',
    //             link : '/apps/academy'
    //         },
    //         {
    //             id      : 'apps.help-center',
    //             title   : 'Pomoc a podpora',
    //             type    : 'basic',
    //             icon    : 'heroicons_outline:support',
    //             link    : '/apps/help-center',
    //         }
    //     ]
    // }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
