/* eslint-disable */
import moment from 'moment';

export const labels = [
    {
        id   : 'f47c92e5-20b9-44d9-917f-9ff4ad25dfd0',
        title: 'nezabudni'
    },
    {
        id   : 'e2f749f5-41ed-49d0-a92a-1c83d879e371',
        title: 'nepodstatne'
    },
    {
        id   : 'b1cde9ee-e54d-4142-ad8b-cf55dafc9528',
        title: 'priority'
    },
    {
        id   : '6c288794-47eb-4605-8bdf-785b61a449d3',
        title: 'A klienti'
    },
    {
        id   : 'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
        title: 'obciansky'
    },
    {
        id   : '2dc11344-3507-48e0-83d6-1c047107f052',
        title: 'tento tyzden'
    }
];

export const notes = [
    {
        id       : '8f011ac5-b71c-4cd7-a317-857dcd7d85e0',
        title    : '',
        content  : 'vyhodnotenie cinnosti obchodnej siete',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
        archived : false,
        createdAt: moment().hour(10).minute(19).subtract(98, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : 'ced0a1ce-051d-41a3-b080-e2161e4ae621',
        title    : '',
        content  : 'niektore fotky z pracovneho vikendu sa nedostali ku vsetkym',
        tasks    : null,
        image    : 'assets/images/cards/18-640x480.jpg',
        reminder : null,
        labels   : [
            'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
            'b1cde9ee-e54d-4142-ad8b-cf55dafc9528'
        ],
        archived : false,
        createdAt: moment().hour(15).minute(37).subtract(80, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : 'd3ac02a9-86e4-4187-bbd7-2c965518b3a3',
        title    : '',
        content  : 'nezabudni zmenit heslo do aplikacie',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : ['6c288794-47eb-4605-8bdf-785b61a449d3'],
        archived : false,
        createdAt: moment().hour(19).minute(27).subtract(74, 'day').toISOString(),
        updatedAt: moment().hour(15).minute(36).subtract(50, 'day').toISOString()
    },
    {
        id       : 'ffd20f3c-2d43-4c6b-8021-278032fc9e92',
        title    : 'navrhy na darcek',
        content  : 'Agata ma narodeniny buduci tyzden, treba vybrat nejaky darcek. nejake doterajsie navrhy ...',
        tasks    : [
            {
                id       : '330a924f-fb51-48f6-a374-1532b1dd353d',
                content  : 'sal',
                completed: false
            },
            {
                id       : '781855a6-2ad2-4df4-b0af-c3cb5f302b40',
                content  : 'kvety',
                completed: true
            },
            {
                id       : 'bcb8923b-33cd-42c2-9203-170994fa24f5',
                content  : 'nahrdelnik',
                completed: false
            }
        ],
        image    : null,
        reminder : null,
        labels   : ['f47c92e5-20b9-44d9-917f-9ff4ad25dfd0'],
        archived : false,
        createdAt: moment().hour(16).minute(4).subtract(47, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : '11fbeb98-ae5e-41ad-bed6-330886fd7906',
        title    : 'Ucast na konferencii',
        content  : '',
        tasks    : [
            {
                id       : '2711bac1-7d8a-443a-a4fe-506ef51d3fcb',
                content  : 'Ranajky do 9:00',
                completed: true
            },
            {
                id       : 'e3a2d675-a3e5-4cef-9205-feeccaf949d7',
                content  : 'recnik: o 11:30 tam musis byt',
                completed: true
            },
            {
                id       : 'bdb4d5cd-5bb8-45e2-9186-abfd8307e429',
                content  : 'poobede stretnutie s petrom',
                completed: false
            }
        ],
        image    : null,
        reminder : moment().hour(11).minute(27).subtract(14, 'day').toISOString(),
        labels   : [
            'b1cde9ee-e54d-4142-ad8b-cf55dafc9528',
            'e2f749f5-41ed-49d0-a92a-1c83d879e371'
        ],
        archived : false,
        createdAt: moment().hour(11).minute(27).subtract(24, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : '6bc9f002-1675-417c-93c4-308fba39023e',
        title    : 'naplanovat pracovny vikend',
        content  : '',
        tasks    : null,
        image    : 'assets/images/cards/31-640x480.jpg',
        reminder : null,
        labels   : [
            '2dc11344-3507-48e0-83d6-1c047107f052',
            'b1cde9ee-e54d-4142-ad8b-cf55dafc9528'
        ],
        archived : false,
        createdAt: moment().hour(9).minute(32).subtract(15, 'day').toISOString(),
        updatedAt: moment().hour(17).minute(6).subtract(12, 'day').toISOString()
    },
    {
        id       : '15188348-78aa-4ed6-b5c2-028a214ba987',
        title    : 'adresa kancelarie',
        content  : 'Za kasarnami 1, 05924 Pezinok',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : ['e2f749f5-41ed-49d0-a92a-1c83d879e371'],
        archived : false,
        createdAt: moment().hour(20).minute(5).subtract(12, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : '1dbfc685-1a0a-4070-9ca7-ed896c523037',
        title    : 'Ulohy',
        content  : '',
        tasks    : [
            {
                id       : '004638bf-3ee6-47a5-891c-3be7b9f3df09',
                content  : 'dat spravit nahradny kluc',
                completed: true
            },
            {
                id       : '86e6820b-1ae3-4c14-a13e-35605a0d654b',
                content  : 'najst upratovaciu sluzbu',
                completed: false
            }
        ],
        image    : null,
        reminder : moment().hour(13).minute(43).subtract(2, 'day').toISOString(),
        labels   : ['bbc73458-940b-421c-8d5f-8dcd23a9b0d6'],
        archived : false,
        createdAt: moment().hour(13).minute(43).subtract(7, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : '49548409-90a3-44d4-9a9a-f5af75aa9a66',
        title    : '',
        content  : 'vecera s rodicmi',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : [
            'f47c92e5-20b9-44d9-917f-9ff4ad25dfd0',
            '6c288794-47eb-4605-8bdf-785b61a449d3'
        ],
        archived : false,
        createdAt: moment().hour(7).minute(12).subtract(2, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : 'c6d13a35-500d-4491-a3f3-6ca05d6632d3',
        title    : '',
        content  : 'kupit odpadove vrecia',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : [
            'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
            '6c288794-47eb-4605-8bdf-785b61a449d3'
        ],
        archived : true,
        createdAt: moment().hour(17).minute(14).subtract(100, 'day').toISOString(),
        updatedAt: null
    },
    {
        id       : '46214383-f8e7-44da-aa2e-0b685e0c5027',
        title    : 'Tim meeting',
        content  : 'bude sa riesit rozvoj obchodneho timu',
        tasks    : null,
        image    : null,
        reminder : null,
        labels   : [
            'e2f749f5-41ed-49d0-a92a-1c83d879e371',
            'b1cde9ee-e54d-4142-ad8b-cf55dafc9528'
        ],
        archived : true,
        createdAt: moment().hour(15).minute(30).subtract(69, 'day').toISOString(),
        updatedAt: null
    }
];
