/* eslint-disable */
import * as moment from 'moment';

export const notifications = [
    {
        id         : '493190c9-5b61-4912-afe5-78c21f1044d7',
        icon       : 'heroicons_solid:star',
        title      : 'Firemné stretnutie',
        description: 'Školenie spojene s vyhodnotením obchodných výsledkov siete',
        time       : moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
        read       : false
    },
    {
        id         : '6e3e97e5-effc-4fb7-b730-52a151f0b641',
        image      : 'assets/images/avatars/male-04.jpg',
        description: '<strong>Štefan Korčmároš</strong> žiada prístup k finančnej analýze <em>Michal Bodrý</em>',
        time       : moment().subtract(50, 'minutes').toISOString(), // 50 minutes ago
        read       : true,
        link       : '/dashboards/project',
        useRouter  : true
    },
    {
        id         : 'ef7b95a7-8e8b-4616-9619-130d9533add9',
        image      : 'assets/images/avatars/male-06.jpg',
        description: '<strong>Ľuboš Michalík</strong> sa stal novým členom obchodnej siete',
        time       : moment().subtract(7, 'hours').toISOString(), // 7 hours ago
        read       : true,
        link       : '/dashboards/project',
        useRouter  : true
    },
    {
        id         : 'eb8aa470-635e-461d-88e1-23d9ea2a5665',
        image      : 'assets/images/avatars/female-04.jpg',
        description: '<strong>Natália Blahová</strong> ti poslala správu',
        time       : moment().subtract(9, 'hours').toISOString(), // 9 hours ago
        read       : true,
        link       : '/dashboards/project',
        useRouter  : true
    }
];
