/* eslint-disable */
export const tags = [
    {
        id   : 'a0bf42ca-c3a5-47be-8341-b9c0bb8ef270',
        title: 'zivotna situacia'
    },
    {
        id   : 'c6058d0d-a4b0-4453-986a-9d249ec230b1',
        title: 'analyza banky'
    },
    {
        id   : 'd3ef4226-ef2c-43b0-a986-3e3e07f32799',
        title: 'platobny kalendar'
    },
    {
        id   : '91658b8a-f382-4b0c-a53f-e9390351c2c5',
        title: 'urgentne'
    }
];
export const tasks = [
    {
        id        : 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        type      : 'section',
        title     : 'Priprava finančného plánu',
        notes     : 'vseobecny popis',
        completed : false,
        dueDate   : '2021-10-18T13:03:37.943Z',
        priority  : 1,
        tags      : [
            '91658b8a-f382-4b0c-a53f-e9390351c2c5',
        ],
        assignedTo: null,
        subTasks  : [],
        order     : 0
    },
    {
        id        : '0fcece82-1691-4b98-a9b9-b63218f9deef',
        type      : 'task',
        title     : 'Zistiť rodné čisla deti',
        notes     : 'Na stretnutí sme to zabudli prebrať',
        completed : false,
        dueDate   : null,
        priority  : 0,
        tags      : [],
        assignedTo: 'e2c81627-a8a1-4bbc-9adc-ac4281e040d4',
        subTasks  : [],
        order     : 1
    },
    {
        id        : '2e6971cd-49d5-49f1-8cbd-fba5c71e6062',
        type      : 'task',
        title     : 'Chybajúci občiansky',
        notes     : 'nemal ho pri seba, posle neskor. pripomenut',
        completed : false,
        dueDate   : '2019-05-24T03:55:38.969Z',
        priority  : 0,
        tags      : [
            'c6058d0d-a4b0-4453-986a-9d249ec230b1',
            '91658b8a-f382-4b0c-a53f-e9390351c2c5'
        ],
        assignedTo: '88a2a76c-0e6f-49da-b617-46d7c3b6e64d',
        subTasks  : [],
        order     : 2
    },
    {
        id        : '1a680c29-7ece-4a80-9709-277ad4da8b4b',
        type      : 'section',
        title     : 'Servis klienta',
        notes     : 'vseobecny popis',
        completed : false,
        dueDate   : '2020-02-08T22:42:35.937Z',
        priority  : 2,
        tags      : [
            'a0bf42ca-c3a5-47be-8341-b9c0bb8ef270',
        ],
        assignedTo: '3e353312-6a9b-46af-adda-5061b06e806b',
        subTasks  : [],
        order     : 9
    },
    {
        id        : 'c49c2216-8bdb-4df0-be25-d5ea1dbb5688',
        type      : 'task',
        title     : 'V aprili 2022 fixacia hypoteka',
        notes     : 'vyrocie fixacie',
        completed : false,
        dueDate   : '2019-08-10T06:18:17.785Z',
        priority  : 1,
        tags      : [
            'a0bf42ca-c3a5-47be-8341-b9c0bb8ef270'
        ],
        assignedTo: '368aab1e-ebce-43ba-8925-4cf13937867b',
        subTasks  : [],
        order     : 10
    },
    {
        id        : '3ef176fa-6cba-4536-9f43-540c686a4faa',
        type      : 'task',
        title     : '12. decembra ma klient narodeniny',
        notes     : 'Culpa duis nostrud qui velit sint magna officia fugiat ipsum eiusmod enim laborum pariatur anim culpa elit ipsum lorem pariatur exercitation laborum do labore cillum exercitation nisi reprehenderit exercitation quis.\n\nMollit aute dolor non elit et incididunt eiusmod non in commodo occaecat id in excepteur aliqua ea anim pariatur sint elit voluptate dolor eu non laborum laboris voluptate qui duis.',
        completed : false,
        dueDate   : '2024-08-23T14:33:06.227Z',
        priority  : 2,
        tags      : [
            '91658b8a-f382-4b0c-a53f-e9390351c2c5'
        ],
        assignedTo: '271e6a06-0d37-433d-bc8d-607b12bcbed9',
        subTasks  : [],
        order     : 11
    },
    {
        id        : '7bc6b7b4-7ad8-4cbe-af36-7301642d35fb',
        type      : 'task',
        title     : 'Vyriesit porovnanie rizikoveho zivotneho poistenia',
        notes     : 'Ea proident dolor tempor dolore incididunt velit incididunt ullamco quis proident consectetur magna excepteur cillum officia ex do aliqua reprehenderit est esse officia labore dolore aute laboris eu commodo aute.\n\nOfficia quis id ipsum adipisicing ipsum eu exercitation cillum ex elit pariatur adipisicing ullamco ullamco nulla dolore magna aliqua reprehenderit eu laborum voluptate reprehenderit non eiusmod deserunt velit magna do.',
        completed : true,
        dueDate   : '2017-08-16T12:56:48.039Z',
        priority  : 1,
        tags      : [
            'd3ef4226-ef2c-43b0-a986-3e3e07f32799',
            'a0bf42ca-c3a5-47be-8341-b9c0bb8ef270'
        ],
        assignedTo: '4d24cf48-a322-4d53-89cb-9140dfd5c6ba',
        subTasks  : [],
        order     : 12
    }
];
